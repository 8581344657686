!function ($, Site) {
    var Push_Gallery = function () {

        var $mainSlider = $('.js-rotator');

        $mainSlider.slick({

            arrows: false,
            autoplay: true,
            autoplaySpeed: 6000,
            fade: true,
            infinite: true,
            slide: '.js-rotator .js-rotator__item',
            slidesToShow: 1,
            variableWidth: false,
            adaptiveHeight: true,
            centerPadding: '0',
            speed: 600,
            pauseOnHover: false
        });
        
    };

    Site.RegisterModule(Push_Gallery);
}(jQuery, Prodo_Site);